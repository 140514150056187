/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { decodeToken, getToken, logout } from 'utils/auth';
import { getEachUser } from 'redux/actions/profile';
import { useSelector, useDispatch } from 'react-redux';
import { svgs } from '../../../assets';
import DropDownMenu from '../DropDown';
import UserTag from '../UserTag';
import {
  MenuWrapper,
  PageName,
  PageWrapper,
  PageNameWrapper,
  Back,
  NotifWrapper,
} from './CandidateMenuBarStyle';
import { getNotification, readNotification, mobileViewerState } from 'redux/actions/user';

const MenuBarCandidate = ({ title, children, canGoBack, handlePrev, ...props }) => {
  const history = useHistory();
  const token = getToken();
  const user = decodeToken(token);
  const [display, setDisplay] = useState(false);
  const toggle = () => setDisplay(!display);
  const options = ['Logout', 'Profile'];
  const dispatch = useDispatch();

  // toggle notification dropdown
  const [showNotify, setShowNotify] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const openDropDown = () => setShowNotify(!showNotify);

  // toggle mark as read dropdown
  const [showOption, setShowOption] = useState({
    status: false,
    id: undefined,
  });

  const openExtraDropDown = (id) => {
    setShowOption((prev) => {
      return { ...prev, status: !prev.status, id };
    });
  };

  const { data } = useSelector((state) => state.profile);
  let profileName = data.length === 0 ? '' : data.firstname + ' ' + data.lastname;
 

  const getUser = useCallback(() => {
    if (data.length < 1) {
      dispatch(getEachUser(user?.user_id));
    }
    // eslint-disable-next-line
  }, [dispatch, user.user_id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const handleBack = () => {
    history.goBack();
  };
  
  const handleLogout = () => {
    logout();
  };

  const handleProfile = () => {
    history.push('/employees/profile');
  };

  const handleMenuClick = (option) => {
    switch (option) {
      case 'Logout':
        handleLogout();
        break;
      case 'Profile':
        handleProfile();
        break;
      default:
        break;
    }
  };

  const Menu = () =>
    options?.map((i, key) => (
      <p
        key={key}
        onClick={() => handleMenuClick(i)}
        className={i === 'logout' ? 'delete' : ''}
      >
        {i}
      </p>
    ));

  // get user notifications
  const { notificationData } = useSelector((state) => state.user);
  let query = '(max-width: 997px)';
  const [matches, setMatches] = useState(query);

  useEffect(() => {
    if (!notificationData?.results) dispatch(getNotification());
    // eslint-disable-next-line
  }, [dispatch]);

  // send mark as read api request
  const sendMarkAsReadDispatch = (id) => {
    dispatch(readNotification(id));
  };

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addListener(listener);

    return () => media.removeListener(listener);
    // eslint-disable-next-line
  }, [matches, query]);

  const togglerMobileViewer = () => {
    dispatch(mobileViewerState(showSidebar));
  };

  return (
    <PageWrapper {...props}>
      <MenuWrapper>
        <PageNameWrapper>
          {canGoBack && <Back onClick={handlePrev || handleBack} />}

          <PageName>
            {matches && (
              <img
                src={svgs.MOBILE_VIEWER}
                alt="mobile"
                onClick={() => {
                  togglerMobileViewer();
                  setShowSidebar(!showSidebar);
                }}
                style={{cursor:'pointer'}}
              />
            )}{' '}
            {title}
          </PageName>
        </PageNameWrapper>
        <div className="user-menu">
          <NotifWrapper>
            {notificationData.total > 0 && (
              <span onClick={openDropDown}>{notificationData.total}</span>
            )}

            <svgs.NOTIF_BELL onClick={openDropDown} />

            <div className={showNotify ? 'notification-dropdown' : 'no-dropdown'}>
              {notificationData?.results === undefined ||
                (notificationData?.results.length === 0 && <li>No notifications yet</li>)}
              {notificationData?.results &&
                notificationData.results.map((message, index) => (
                  <div key={message.id}>
                    <div className="notification-list">
                      <li>
                        {message.sender} has sent a {message.subject} mail
                      </li>
                      <svgs.HORIZONTAL_ELLIPSES
                        onClick={() => {
                          openExtraDropDown(message.id);
                        }}
                      />
                    </div>
                    <div
                      className={
                        showOption.status && showOption.id === message.id
                          ? 'mark-read'
                          : 'no-dropdown'
                      }
                    >
                      <p
                        onClick={() => {
                          sendMarkAsReadDispatch(message.id);
                        }}
                      >
                        mark as read
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </NotifWrapper>
          {!matches && (
            <DropDownMenu display={display} toggle={toggle} renderMenu={Menu} right={0}>
              <div onClick={toggle} style={{ display: 'flex', alignItems: 'center' }}>
                <UserTag
                  image={data?.image ? data?.image : svgs.AVATAR}
                  name={`${profileName}`}
                />
                <svgs.CARET_DOWN className="clickable" />
              </div>
            </DropDownMenu>
          )}

          {matches && (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <UserTag image={data?.image ? data?.image : svgs.AVATAR} name={``} />
                {display && (
                  <svgs.CARET_DOWN
                    className="clickable"
                    onClick={() => setDisplay(false)}
                  />
                )}
                {!display && (
                  <svgs.CARET_DOWN
                    className="clickable"
                    onClick={() => setDisplay(true)}
                  />
                )}
              </div>
              <div className="move-down-profile">
                <DropDownMenu
                  display={display}
                  toggle={toggle}
                  renderMenu={Menu}
                  right={0}
                ></DropDownMenu>
              </div>
            </>
          )}
        </div>
      </MenuWrapper>
      {children}
    </PageWrapper>
  );
};

export default MenuBarCandidate;
