export const ADD_ADMIN_REQUEST = 'ADD_ADMIN_REQUEST';
export const ADD_ADMIN_FAILURE = 'ADD_ADMIN_FAILURE';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const GET_ADMIN_REQUEST = 'GET_ADMIN_REQUEST';
export const GET_ADMIN_ERROR = 'GET_ADMIN_ERROR';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_SINGLE_ADMIN_SUCCESS = 'GET_SINGLE_ADMIN_SUCCESS';

export const GET_BANKS_REQUEST = 'GET_BANKS_REQUEST';
export const GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS';
export const GET_BANKS_FAILURE = 'GET_BANKS_FAILURE';

export const ADD_BANKS_REQUEST = 'ADD_BANKS_REQUEST';
export const ADD_BANKS_SUCCESS = 'ADD_BANKS_SUCCESS';
export const ADD_BANKS_FAILURE = 'ADD_BANKS_FAILURE';

export const EDIT_BANKS_REQUEST = 'EDIT_BANKS_REQUEST';
export const EDIT_BANKS_SUCCESS = 'EDIT_BANKS_SUCCESS';
export const EDIT_BANKS_FAILURE = 'EDIT_BANKS_FAILURE';

export const DELETE_BANKS_REQUEST = 'DELETE_BANKS_REQUEST';
export const DELETE_BANKS_SUCCESS = 'DELETE_BANKS_SUCCESS';
export const DELETE_BANKS_FAILURE = 'DELETE_BANKS_FAILURE';

export const GET_PENSION_REQUEST = 'GET_PENSION_REQUEST';
export const GET_PENSION_SUCCESS = 'GET_PENSION_SUCCESS';
export const GET_PENSION_FAILURE = 'GET_PENSION_FAILURE';

export const ADD_PENSION_REQUEST = 'ADD_PENSION_REQUEST';
export const ADD_PENSION_SUCCESS = 'ADD_PENSION_SUCCESS';
export const ADD_PENSION_FAILURE = 'ADD_PENSION_FAILURE';

export const EDIT_PENSION_REQUEST = 'EDIT_PENSION_REQUEST';
export const EDIT_PENSION_SUCCESS = 'EDIT_PENSION_SUCCESS';
export const EDIT_PENSION_FAILURE = 'EDIT_PENSION_FAILURE';

export const DELETE_PENSION_REQUEST = 'DELETE_PENSION_REQUEST';
export const DELETE_PENSION_SUCCESS = 'DELETE_PENSION_SUCCESS';
export const DELETE_PENSION_FAILURE = 'DELETE_PENSION_FAILURE';


export const GET_SIGNATURE_REQUEST = 'GET_SIGNATURE_REQUEST';
export const GET_SIGNATURE_SUCCESS = 'GET_SIGNATURE_SUCCESS';
export const GET_SIGNATURE_FAILURE = 'GET_SIGNATURE_FAILURE';

export const ADD_SIGNATURE_REQUEST = 'ADD_SIGNATURE_REQUEST';
export const ADD_SIGNATURE_SUCCESS = 'ADD_SIGNATURE_SUCCESS';
export const ADD_SIGNATURE_FAILURE = 'ADD_SIGNATURE_FAILURE';

export const EDIT_SIGNATURE_REQUEST = 'EDIT_SIGNATURE_REQUEST';
export const EDIT_SIGNATURE_SUCCESS = 'EDIT_SIGNATURE_SUCCESS';
export const EDIT_SIGNATURE_FAILURE = 'EDIT_SIGNATURE_FAILURE';

export const DELETE_SIGNATURE_REQUEST = 'DELETE_SIGNATURE_REQUEST';
export const DELETE_SIGNATURE_SUCCESS = 'DELETE_SIGNATURE_SUCCESS';
export const DELETE_SIGNATURE_FAILURE = 'DELETE_SIGNATURE_FAILURE';
