import React from 'react';
import PageContainer from '../../components/common/PageContainer';
import { HomeWrapper } from './styles';
import MenuBar from 'components/common/MenuBar/CandidateMenuBar';
import {decodeToken, getToken } from 'utils/auth';
import { Redirect } from 'react-router-dom';




const Home = () => {

  const token = getToken();
  const user = decodeToken(token);
  const isCandidate = !['ADMIN', 'SUPERADMIN'].some((val) => user?.roles?.includes(val));
  if (isCandidate) {
    return <Redirect to={`/candidate/profile/summary`} />;
  }

  return (
    <>
   <MenuBar title="Home" />
    <PageContainer>
      <HomeWrapper>
        <div className="app">
          <p>Coming soon</p>
        </div>
      </HomeWrapper>
    </PageContainer>
    </>
  );
};

export default Home;
