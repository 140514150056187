import {
  ADD_ADMIN_FAILURE,
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_SUCCESS,
  GET_ADMIN_ERROR,
  GET_ADMIN_REQUEST,
  GET_ADMIN_SUCCESS,
  GET_SINGLE_ADMIN_SUCCESS,
  GET_BANKS_REQUEST,
  GET_BANKS_SUCCESS,
  GET_BANKS_FAILURE,
  ADD_BANKS_REQUEST,
  ADD_BANKS_SUCCESS,
  ADD_BANKS_FAILURE,
  EDIT_BANKS_REQUEST,
  EDIT_BANKS_SUCCESS,
  EDIT_BANKS_FAILURE,
  DELETE_BANKS_REQUEST,
  DELETE_BANKS_SUCCESS,
  DELETE_BANKS_FAILURE,
  GET_PENSION_REQUEST,
  GET_PENSION_SUCCESS,
  GET_PENSION_FAILURE,
  ADD_PENSION_REQUEST,
  ADD_PENSION_SUCCESS,
  ADD_PENSION_FAILURE,
  EDIT_PENSION_REQUEST,
  EDIT_PENSION_SUCCESS,
  EDIT_PENSION_FAILURE,
  DELETE_PENSION_REQUEST,
  DELETE_PENSION_SUCCESS,
  DELETE_PENSION_FAILURE,
  GET_SIGNATURE_REQUEST,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_FAILURE,
  ADD_SIGNATURE_REQUEST,
  ADD_SIGNATURE_SUCCESS,
  ADD_SIGNATURE_FAILURE,
  EDIT_SIGNATURE_REQUEST,
  EDIT_SIGNATURE_SUCCESS,
  EDIT_SIGNATURE_FAILURE,
  DELETE_SIGNATURE_REQUEST,
  DELETE_SIGNATURE_SUCCESS,
  DELETE_SIGNATURE_FAILURE,
} from '../actionTypes/settings';

const initialState = {
  loading: false,
  addAdminError: null,
  addAdminSuccess: false,
  getAdminSuccess: false,
  getAdminError: true,
  admin: null,
  admins: [],
  banks: [],
  pensionManagers: [],
  signature: [],
};

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        addAdminError: null,
        addAdminSuccess: false,
      };
    case ADD_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        addAdminError: payload,
        addAdminSuccess: false,
      };
    case ADD_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        addAdminError: null,
        addAdminSuccess: true,
      };
    case GET_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        admin: null,
      };
    case GET_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
        getAdminError: payload,
      };
    case GET_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: payload,
      };
    case GET_SINGLE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        admin: payload,
      };
    case GET_BANKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BANKS_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: payload,
      };
    case GET_BANKS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case EDIT_BANKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_BANKS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_BANKS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_BANKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_BANKS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_BANKS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DELETE_BANKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BANKS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_BANKS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_PENSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PENSION_SUCCESS:
      return {
        ...state,
        loading: false,
        pensionManagers: payload,
      };
    case GET_PENSION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case EDIT_PENSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PENSION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_PENSION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_PENSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PENSION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PENSION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PENSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PENSION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PENSION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case GET_SIGNATURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SIGNATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        signature: payload,
      };
    case GET_SIGNATURE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case EDIT_SIGNATURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_SIGNATURE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_SIGNATURE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_SIGNATURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_SIGNATURE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_SIGNATURE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SIGNATURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SIGNATURE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SIGNATURE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default settingsReducer;
