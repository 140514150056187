import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { PRIMARY_COLOR, RED_COLOR } from 'assets/colors';

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25% auto 40px;
`;
export const AttachmentTab = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
`;

export const AttachmentLinks = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  padding: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-radius; 6px;
  border: 1px solid #ccc;
  background-color: '#F2F3F4';
  cursor: pointer;
  img{
    margin-left: 10px;
  }
`;

export const EmptyStateText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  justify-content: center;
`;

export const EmptyStateTextParagraph = styled.p`
  text-align: center;
  width: 345px;
`;

export const EmptyStateTextbtn = styled.p`
  margin-top: 40px;
  text-align: center;
`;

export const SearchFilterWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(199, 223, 241, 0.25);
  position: fixed;
  left: 250px;
  right: 0;
  z-index: 10;
  margin-top: 75px;
`;

//table row****************************************

//*************************************************/

export const CheckboxContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  &.checkboxstyle {
    cursor: pointer;
    margin-top: -10px;
    input {
      opacity: 0;
      position: relative;
      top: 16px;
    }

    input + .checked_img {
      display: none;
    }

    input:checked + .checked_img {
      display: inline-block;
    }

    input:checked ~ .unchecked_img {
      display: none;
    }
  }
`;

export const TableRow = styled.div.attrs((props) => ({
  className: props.className,
}))`
  &.employee-details-tbl {
    padding: 20px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 40px 40px repeat(6, 1fr) 40px;
    align-items: center;
    justify-content: start;
    border-top: 1px solid #f2f2f2;
    font-size: 14px;
    color: #666666;
    &.change_request {
      grid-template-columns: 40px 40px 120px 200px 200px 400px 40px;
    }
    .more-icon {
      cursor: pointer;
    }

    .dropdown-menu {
      opacity: 1 !important;
      padding: 0.5rem 1.5rem !important;
      pointer-events: auto !important;
      border-radius: 5px;
      left: -190px !important;
      min-width: 240px !important;
      ul {
        a {
          text-decoration: none;
          color: #333;
          font-size: 14px;
          font-weight: 500;
          display: block;
          padding: 10px 20px;
          &:hover {
            background: #fafafa;
          }
        }
      }
    }
    div.dropdown {
      position: relative;
    }
    div:first-child {
      display: grid;
      justify-content: center;
      .candidate-name {
        font-weight: 500;
        font-size: 16px;
        color: #333;
      }
    }
    .dropdown-item {
      padding: 0.5rem 0.5rem;
    }
    .candidate-email {
      font-weight: normal;
      word-wrap: break-word;
      width: 150px;
      display: inline-block;
    }

    .activate {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 9px;
      color: #15a412;
      background: #dcffd6;
      border-radius: 6px;
      padding: 2px 6px 2px;
      margin-left: 1px;
    }
    .deactivate {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 9px;
      color: #eb5757;
      background: #ffdbd6;
      border-radius: 6px;
      padding: 2px 6px 2px;
      margin-left: 1px;
    }
  }
`;
//***********************************************
//***************************************************

export const AddToTopDiv = styled.div`
  margin-top: 10px;
  position: relative;
  z-index: 4;

  .right-items-01 {
    position: absolute;
    right: 0;
  }
  .right-items-01 .dropdown-menu.show {
    right: -20px !important;
    transform: translate(-130px, 35px) !important;
    padding: 20px 10px;
  }
  .right-items-01 .dropdown-menu.show a {
    text-align: left !important;
    color: #333;
    padding: 10px 20px;
  }
`;

export const ChevronDiv = styled.span`
  display: inline-block;
  margin-left: 10px;
  img {
    display: block;
    position: relative;
    top: 3px;
    cursor: pointer;
  }
`;

export const TablerowImgDiv = styled.span`
  display: inline-block;
  img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
`;

//*****selectaddmodal******************************************

//***************************************************

export const WrapperModal = styled(Modal)`
  .modal-content {
    top: 80px;
  }

  .download_spreadsheet {
    padding: 40px 10px 0px;
    text-align: center;
    .browse {
      color: #1a21ba;
    }
    p {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      margin-bottom: 40px;
    }
    button {
      border: none;

      outline: none;
    }
    .btn_with_image {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      border-radius: 5px;
      width: 228px;
      margin: 0 auto;
      margin-bottom: 40px;

      img {
        display: inline-block;
        position: relative;
        left: 10px;
      }
    }
    .react-file-reader {
      margin-top: -30px;
      padding-top: 30px;
      cursor: pointer;
    }

    .react-file-reader input {
      cursor: pointer;
      z-index: 200;
      width: 100% !important;
      height: 100px;
      position: absolute !important;
      left: 0;
    }
    .clear-edictor-para {
      p {
        margin-bottom: 0px;
      }
    }
  }
`;

export const WrapperSelectAddModalBtn = styled.div`
  padding: 40px 120px 60px 100px;
  button {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
    border-radius: 5px;
    margin: 5px 0;
  }
`;

//*****addemployeemodal******************************************

//***************************************************

export const WrapperAddEmployeeModal = styled(Modal)`
   {
    display: block;
    .custom-search {
      position: relative;
      display: block;
    }
    .modal-dialog {
      max-width: ${(props) => (props.maxWidth ? props.maxWidth : ' 540px')} !important;
      margin: 1.75rem auto;
    }
    .chevron_down_21 {
      height: 20px;
      position: absolute;
      right: 10px;
      top: 35px;
    }
    .dropdown-21 {
      position: absolute;
      background-color: #f9f9f9;
      min-width: 100%;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      padding: 12px 16px;
      z-index: 1;
      top: 70px;
      border: 1px solid #c1c7d0;
      color: #5e6c84;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      background: #f4f5f7;
      display: none;
      height: 150px;
      overflow-y: scroll;
    }
    .dropdown-21 p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px !important;
      line-height: 15px;
      padding: 4px 0px;
    }
  }
`;

/*************************************************
 
 ****************************************************/

export const WrapperConfirmEmployeeModal = styled(Modal)`
   {
    display: block;
    .custom-search {
      position: relative;
      display: block;
    }
    .modal-dialog {
      max-width: 540px !important;
      margin: 1.75rem auto;
    }
    .chevron_down_21 {
      height: 20px;
      position: absolute;
      right: 10px;
      top: 35px;
    }
    .dropdown-21 {
      position: absolute;
      background-color: #f9f9f9;
      min-width: 100%;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      padding: 12px 16px;
      z-index: 1;
      top: 70px;
      border: 1px solid #c1c7d0;
      color: #5e6c84;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      background: #f4f5f7;
      display: none;
      height: 150px;
      overflow-y: scroll;
    }
    .dropdown-21 p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px !important;
      line-height: 15px;
      padding: 4px 0px;
    }

    &.confirm-employee {
      padding: 0px;
    }
    .delete-content {
      &.confirm-employee h6 {
        text-align: left;
      }
      h6 {
        padding-bottom: 30px;
      }

      button {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 5px 15px;
        margin: 2px;
        border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
        color: #172b4d;
      }
      button:nth-of-type(2) {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 5px 15px;
        margin: 2px;
        border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
        color: #eb5757;
      }
      .labels {
        padding: 4px 8px 6px;
        background: #dfe1e6;
        border-radius: 4px;
        display: inline-block;
        width: auto;
        margin: 3px 5px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #5e6c84;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
      .confirm-employees-h12-1 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #333333;
        padding: 10px 0;
      }
      .confirm-employees-h12 {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #666666;
        padding: 10px 0;
      }
    }
  }
`;

export const Error = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
`;

export const FormContainer = styled.form.attrs((props) => ({
  className: props.className,
}))`
  padding: 40px;
  &.document {
    padding: 10px;
    text-align: left;
  }
`;

export const OverlayAdd = styled.div`
   {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 22;
    cursor: pointer;
    display: none;
  }

  #text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
`;

//****FILEUPLOAD******************************************

//***************************************************

export const FileUploadContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  &.fileupload_wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin: -20px 0px 0px;

    .fileupload_style input {
      opacity: 0;
      height: 150px;
      position: absolute;
      right: 10px;
      width: 100%;
      cursor: pointer;
      display: block !important;
      background: greenyellow;
      top: 5px;
    }

    .upload_icon_img {
      display: inline-block;
      margin: 0 100px;
    }

    .fileupload_style > .filesize {
      margin-top: -35px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #5e6c84;
    }
    .fileupload_style > .undertext {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #5e6c84;
    }
    ~ .btn_with_image {
      margin-top: -20px;
    }

    .fileupload_style .file_name {
      display: inline-block;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 17px;
      color: #0050c8;
      flex: none;
      order: 0;
      align-self: center;
      flex-grow: 0;
      margin: 4px 0px;
    }

    .fileupload_style .wrap_file_name_close img {
      width: 12px;
    }
    .fileupload_style > .wrap_file_name_close {
      background: #deebff;
      padding: 5px 5px;
      border-radius: 4px;
      position: relative;
      top: -30px;
    }
  }
`;

export const ModalFooter = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  background-color: #fff;
  width: 100%;
  padding: 10px 10px 0px 10px;
  &.footer_modal:after {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0px;
    height: 50px;
    border: 1px solid #f0f0f0;
    content: '';
  }
`;

export const ModalButton = styled.button.attrs((props) => ({
  className: props.className,
}))`
  flex: 1;
  text-align: start;
  text-align: center;
  position: relative;
  top: -10px;
  font-weight: 500 !important;
  font-size: 15px;
  color: ${({ action }) => (action ? PRIMARY_COLOR : RED_COLOR)};
  border-left: ${({ action }) => (action ? '1px solid #F0F0F0' : 'none')};
  cursor: pointer;
  border: none;
  background: #fff;
  padding: 10px 0px;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  &:focus {
    outline: none;
  }

  .btn-2 {
    border-right: 0px solid #eee;
  }
`;

/*****************************************
 
****************************************/

export const ActionContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  padding: 40px;
  text-align: center;
  .comment-form-content form {
    padding: 0;
  }
  &.action-confirm {
    padding: 10px;
  }

  .comment-form-content textarea {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #5e6c84;
  }

  .wrap-file-upload {
    background: #f4f5f7;
    border: 1px dashed #c1c7d0;
    box-sizing: border-box;
    border-radius: 4px;
    height: 128px;
  }

  .wrap-file-upload .fileupload_wrapper .fileupload_style > .filesize {
    margin-top: 35px;
  }
  .wrap-file-upload .fileupload_wrapper .upload_icon_img {
    display: inline-block;
    margin: 0 100px;
    position: relative;
    top: 40px;
  }
  .wrap-file-upload .fileupload_wrapper .fileupload_style > .undertext {
    position: relative;
    top: 30px;
    margin-top: 10px;
  }
  .wrap-file-upload .browse {
    color: #1a21ba;
  }

  .wrap-file-upload .file-img {
    position: relative;
    top: 50px;
  }

  .wrap-file-upload .fileupload_wrapper {
    position: relative;
    overflow: visible;
    display: inline-block;
    margin: -20px 0px 0px;
  }

  .wrap-file-upload .fileupload_wrapper .fileupload_style > .wrap_file_name_close {
    background: #deebff;
    padding: 5px 5px;
    border-radius: 4px;
    position: relative;
    top: 60px;
  }
`;

export const MessageHeader = styled.div`
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19.36px
  color: #333333;
`;

export const MessageSubtitle = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #666666;
`;

//table row****************************************

//*************************************************/

export const ConfirmContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  &.confirm-employee {
    padding: 0px;
  }
  .delete-content {
    &.confirm-employee h6 {
      text-align: left;
    }
    h6 {
      padding-bottom: 30px;
    }

    button {
      border: 1px solid #d9d9d9;
      background: #fff;
      padding: 5px 15px;
      margin: 2px;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      color: #172b4d;
    }
    button:nth-of-type(2) {
      border: 1px solid #d9d9d9;
      background: #fff;
      padding: 5px 15px;
      margin: 2px;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      color: #eb5757;
    }
    .labels {
      padding: 4px 8px 6px;
      background: #dfe1e6;
      border-radius: 4px;
      display: inline-block;
      width: auto;
      margin: 3px 5px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #5e6c84;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .confirm-employees-h12-1 {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #333333;
      padding: 10px 0;
    }
    .confirm-employees-h12 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #666666;
      padding: 10px 0;
    }
  }
`;

//*****view-details******************************************

//***************************************************

export const SummaryHeader = styled.div.attrs((props) => ({
  className: props.className,
}))`
   {
    margin-top: 70px;
    background-color: #fff;
    padding: 20px 240px 0;
    box-shadow: 0px 1px 2px rgba(199, 223, 241, 0.25);
    position: fixed;
    left: 250px;
    right: 0;
    z-index: 10;
    top: 0;
  }

  .topnav {
    padding: 12px 16px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .topnav .nav_links-1 {
    border-bottom: 3px solid transparent;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #333333;
    text-transform: capitalize;
    cursor: pointer;
    padding-bottom: 7px;
  }
  .topnav a {
    text-decoration: none;
    outline: none;
    position: relative;
    top: -7px;
  }
  .topnav .nav_links-1:hover {
    border-bottom: 3px solid #1a21ba;
  }
  .topnav .nav_links-1.active {
    border-bottom: 3px solid #1a21ba;
    color: #1a21ba;
    box-shadow: 10px 0 0 0 #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
  }
  .change-request-badge {
    display: inline-block;
    background: #deebff;
    border-radius: 100px;
    text-align: center;
    width: 24px !important;
    height: 22px;
    padding: 3px 8px 5px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 14px;
    text-transform: capitalize;
    color: #1a21ba;
  }
`;

//*****summary page******************************************

//***************************************************

export const SummaryPageContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .page-wrapper-1 {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
    border-radius: 10px;
    width: 920px;
    height: 507px;
    margin: 20px auto;
    padding: 30px 40px 20px;
    margin-top: 150px;
    margin-bottom: 50px;
    &.employee-view {
      margin-top: 50px;
      width: unset;
    }
  }

  .page-wrapper-1 h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    padding-bottom: 10px;
  }
  .page-content-1 {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 5px;
    height: 396px;
  }
  .summary-image-wrapper {
    text-align: center;
    margin: 5% 0 0;
  }

  .summary-image-wrapper img {
    width: 109px;
    height: 109px;
    border-radius: 50%;
    object-fit: cover;
  }

  .summary-image-wrapper p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .summary-image-wrapper p:nth-of-type(2) {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #666666;
  }

  .summary-image-text {
    padding: 10px 0;
  }

  .summary-contact {
    text-align: left;
    padding: 40px 40px 0;
  }
  .summary-contact p:nth-of-type(1) {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #666666;
  }

  .summary-contact hr {
    border: 1px solid #f0f0f0;
  }

  .summary-contact .details > p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 17px;

    color: #666666;
  }
  .summary-contact span {
    display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
  }
  .summary-contact button {
    float: right;
    margin-top: -35px;
    background: #1a21ba;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 118px;
    height: 42px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: none;
    color: #fff;
  }
  @media screen and (max-width: 997px) {
    .summary-contact {
      text-align: left;
      padding: 40px 20px 0;
    }

    .summary-contact span {
      font-size: 15px;
      word-break: break-word;
    }
  }
`;

/*****profiledata page*******************************

***************************************************/

export const ProfileDataPageContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
   {
    margin: 150px 50px 0px;
    &.employee-page {
      margin: 70px 0px 0px;
    }
    .p3_col {
      flex: 0 0 28.4%;
      max-width: 28.4%;
    }

    .p9_col {
      flex: 0 0 71.6%;
      max-width: 71.6%;
    }
    .p9_col .dropdown-menu {
      min-width: unset !important;
    }
    .p9_col .dropdown-item {
      padding: 5px 10px 5px 3px;
      font-size: 12px;
    }
    .siderbar_wrapper_p3 {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      border-radius: 5px;
      height: 590px;
    }
    .siderbar_wrapper_p3.documents {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      border-radius: 5px;
      height: auto;
    }

    .top_menu {
      padding: 30px;
      text-align: center;
    }
    .top_menu img {
      height: 64px;
      width: 64px;
      object-fit: cover;
      border-radius: 50%;
    }
    .top_menu .summary-image-text {
      margin: 10px 0;
    }

    .top_menu .summary-image-text ~ hr {
      margin: 20px 5px 0px -10px;
      width: 230px;
    }
    .sidebar_menu_list a {
      -webkit-text-decoration: none;
      -webkit-text-decoration: none;
      text-decoration: none;
      outline: none;
      padding: 10px 20px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #333333;
      display: block;
      border: 1px solid #transparent;
    }

    .sidebar_menu_list a.nav-item-active {
      color: #1a21ba;
      border-right: 3px solid #1a21ba;
      color: #1a21ba;
      border-bottom: 1px solid #fff;
      border-top: 1px solid #fff;
    }

    .sidebar_menu_list a:hover {
      color: #1a21ba;
    }

    .top_menu .summary-image-text p {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: #333333;
    }

    .top_menu .summary-image-text p:nth-of-type(2) {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #666666;
    }

    .mainview_wrapper_p3 {
      background: #fff;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      border-radius: 5px;
    }

    .mainview_wrapper_p3 .label_name {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;

      color: #333333;
      padding-bottom: 10px;
    }

    .mainview_wrapper_p3 .detail_to_wrap {
      background: #ffffff;
      border: 1px solid #f0f0f0;
      box-sizing: border-box;
      border-radius: 5px;
      margin-bottom: 20px;
    }

    .mainview_wrapper_p3 .table-header_1 {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;

      color: #333333;
    }

    .mainview_wrapper_p3 .documents_tbl th {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #666666;
      width: 30%;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 20px;
    }
    .mainview_wrapper_p3 .documents_tbl td {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #333333;
      padding-bottom: 20px;
    }

    .mainview_wrapper_p3 .documents_tbl td.action-td span {
      opacity: 0.3;
    }

    .mainview_wrapper_p3 .documents_tbl td.status {
      padding-bottom: 10px;
    }
    .mainview_wrapper_p3 tr {
      border-bottom: 1px solid #f2f2f2;
    }
    .mainview_wrapper_p3 td:nth-of-type(4) {
      margin-left: 20px;
      display: inline-block;
      padding-top: 20px;
    }
    .mainview_wrapper_p3 td:nth-of-type(5) {
      padding-top: 10px;
    }
    .mainview_wrapper_p3 .document_icon_approve {
      margin: 10px 15px;
    }
    .mainview_wrapper_p3 .ellipse_td .dropdown-menu.show {
      width: 100%;
      font-size: 15px;
      transform: translate(-65%, 50px) !important;
      background: #ff;
    }
    .mainview_wrapper_p3 .ellipse_td .dropdown-menu.show a {
      padding: 10px !important;
    }

    .line_1 {
      display: flex;
      padding: 10px 20px;
      align-items: center;
      justify-content: space-between;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #333333;
    }
    .line_1 img {
      margin: 0 10px;
    }
    .form_grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }
    .form_grid.form-21 {
      gap: 0 15px;
    }

    .form_grid .form-group {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #5e6c84;
    }
    .form_grid p {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
    }
    .form_grid label {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #666666;
    }

    .form_to_wrap form {
      padding: 20px;
    }
    .form_to_wrap.pad-15 form {
      padding: 5px 20px 5px;
    }
    .cancel_btn_21 {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 5px;
      width: 67px;
      height: 40px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      margin: 10px 10px;
    }

    .save_btn_21 {
      width: 124px;
      height: 40px;
      background: #1a21ba;
      border-radius: 5px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      margin: 10px 0px;
      border: none;
    }

    .save_btn_21:disabled {
      opacity: 0.6;
    }
    .buttons_21 {
      text-align: right;
    }
    .fields-hide {
      display: none;
    }
    .add-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .add-flex span {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      text-align: right;
      color: #1a21ba;
      cursor: pointer;
    }
    .p-with-flex .showthis {
      display: inline-block;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #666666;
      margin-right: 5px;
    }
    .item_21_p2 {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #999999;
    }
    .mv_21_top {
      margin-top: -5px;
    }
    .form_no_grid {
      position: relative;
      top: -15px;
    }
    .custom-search {
      position: relative;
    }

    .custom-search.jobinfo .dropdown-21 {
      top: 50px !important;
    }
    .chevron_down_21 {
      height: 20px;
      position: absolute;
      right: 10px;
      top: 35px;
    }
    .dropdown-21 {
      position: absolute;
      background-color: #f9f9f9;
      min-width: 100%;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      padding: 12px 16px;
      z-index: 1;
      top: 70px;
      border: 1px solid #c1c7d0;
      color: #5e6c84;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      background: #f4f5f7;
      display: none;
      height: 150px;
      overflow-y: scroll;
    }
    .dropdown-21 p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px !important;
      line-height: 15px;
      padding: 4px 0px;
    }
    .form_to_wrap_grid {
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;
    }
  }
`;

/*************************************
 
 ************************************/

export const StateContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
   {
    width: 100%;
    background: #fff;
    height: 100%;
    padding: 34px 50px;

    .h3-01 {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #333333;
      position: relative;
      top: -20px;
      display: block;
    }
    .p3-01 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #666666;
      position: relative;
      top: -10px;
      display: block;
    }
    p button {
      position: relative;
      top: -10px;
      background: #1a21ba;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      border-radius: 5px;
      width: 177px;
      height: 40px;
    }
  }
`;

/*************************************
 change request 
 ************************************/

export const RequestWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
   {
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
  }
  .main-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-items .left-content {
    display: flex;
  }

  .main-items .side-old {
    margin-left: 10px;
  }

  .main-items .left-content .old {
    background: #333333;
    border-radius: 4px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    /* White */
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 4px 0px;
    width: 30px;
    height: 24px;
    padding: 5px 5px;
    text-transform: capitalize;
  }
  .side-old .item-title {
    ont-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #666666;
  }

  .side-old .item-name {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #666;
    width: 300px;
  }

  .main-items .right-content .accept {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #15a412;
    padding: 5px 20px;
  }

  .main-items .right-content .cancel {
    background: #ffffff;
    border: 0px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #eb5757;
    padding: 5px 20px;
  }

  .new-info {
    margin-top: 15px;
  }

  .main-items.new-info .left-content .old {
    background: #deebff;
    border-radius: 4px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0050c8;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 4px 0px;
    flex-grow: 0;
    margin: 4px 0px;
    width: 30px;
    height: 24px;
    padding: 5px 5px;
    text-transform: capitalize;
  }

  .main-items.new-info .side-old .item-name {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333;
  }
  .main-items .left-content .new {
    width: 36px !important;
  }
  .item-name {
    display: block;
  }
  @media screen and (max-width: 997px) {
    .main-items.new_info.candidate .side-old .item-name {
      font-size: 13px;
    }
    .main-items.old_info.candidate .side-old .item-name {
      font-size: 13px;
    }
    .new-info.candidate .right-content {
      font-size: 13px;
    }
  }
`;

/*****profiledata page*******************************

***************************************************/

export const DocumentspageContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
   {
    margin: 150px 50px 0px;
    .p3_col {
      flex: 0 0 28.4%;
      max-width: 28.4%;
    }

    .p9_col {
      flex: 0 0 71.6%;
      max-width: 71.6%;
    }
    .siderbar_wrapper_p3 {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      border-radius: 5px;
      height: 590px;
    }
    .siderbar_wrapper_p3.documents {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(199, 223, 241, 0.25);
      border-radius: 5px;
      height: auto;
    }
  }
`;

/*****comfirmemployee page*******************************

***************************************************/

export const ConfirmEmployeeContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  padding-bottom: 40px;
  .center-div {
    width: 540px;
    margin: 180px auto 0px;
    padding: 20px;
    position: relative;
    background: #ffffff !important;
  }
  .address p {
    width: 217px;
    float: right;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
  }

  .letter-head {
    margin-top: 40px;
    text-align: center;
  }
  .letter-head h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
  }
  .letter-body {
    margin-top: 40px;
  }

  .letter-body p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
  .letter-body p:nth-of-type(1) {
    margin-bottom: 20px;
  }
  .letter-body .lemon {
    color: #15a412;
  }
  .edit {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translate(50% 0%);
  }
  .confirm-letter-default .ql-container {
    height: auto;
    margin: 0px;
    position: relative;
    background: #ffffff !important;
    border-radius: 10px !important;
  }
  .confirm-letter-default .ql-container.ql-snow {
    border: 0px solid #ccc !important;
  }

  .quill.confirm-letter-default .ql-editor p {
    font-size: 14px;
    line-height: 10px;
    font-size: 14px;
    line-height: 18px;
  }
  .quill.confirm-letter-default .ql-editor {
    background: #fff;
  }

  .quill.confirm-letter-default {
    border-radius: 4px;
    border: 1px solid #fff;
  }
`;

//*****addemployeemodal******************************************

//***************************************************

export const ConfirmEmployeeModal = styled(Modal)`
   {
    display: block;
    .custom-search {
      position: relative;
      display: block;
    }
    .modal-dialog {
      max-width: 540px !important;
      margin: 1.75rem auto;
    }
    .chevron_down_21 {
      height: 20px;
      position: absolute;
      right: 10px;
      top: 35px;
    }
    .dropdown-21 {
      position: absolute;
      background-color: #f9f9f9;
      min-width: 100%;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      padding: 12px 16px;
      z-index: 1;
      top: 70px;
      border: 1px solid #c1c7d0;
      color: #5e6c84;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      background: #f4f5f7;
      display: none;
      height: 150px;
      overflow-y: scroll;
    }
    .dropdown-21 p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px !important;
      line-height: 15px;
      padding: 4px 0px;
    }

    .labels {
      padding: 4px 8px 6px;
      background: #dfe1e6;
      border-radius: 4px;
      display: inline-block;
      width: auto;
      margin: 3px 5px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #5e6c84;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .mt-label {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #091e42;
    }
  }
  .bottom-btn {
    margin: -20px 0px 50px;
    padding: 0px 40px;
  }
  .bottom-btn button {
    height: 36px;
    border: 1px solid #c1c7d0;
    box-sizing: border-box;
    border-radius: 100px;
  }
  .bottom-btn img {
    height: 13px;
    float: right;
  }
  .bottom-btn img {
    height: 13px;
    float: right;
    display: inline-block;
    margin-top: 5px;
  }

  .bottom-btn .btn-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1a21ba;
  }
`;
