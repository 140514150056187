import React, { Suspense } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import candidateRoutes from '../../../routes/candidate';
import sharedRoutes from 'routes/shared';
import AuthorizedRoute from '../AuthorizedRoute';
import CandidateSidebar from '../../Sidebar/candidateSidebar';
import Sidebar from '../../Sidebar';
import { isAuthenticated, decodeToken, getToken } from '../../../utils/auth';



/**
 * Renders the component if the user is authenticated
 *
 * @param {Component} Component
 *
 * @returns {JSX}
 */
const renderComponent = (Component) => (props) => {
  return <Component {...props} />;
};

/**
 * This Component returns a new
 * route based on authenticated status
 *
 * @returns {JSX}
 */
const AuthenticatedRoute = (props) => {

  const allowedRef = [...candidateRoutes, ...sharedRoutes].map(
    (each) => each.path,
  );


  const { component: Component, path, ...rest } = props;
  const checkisAuthenticated = isAuthenticated();
  const token = getToken();
  const user = decodeToken(token);
  const location = useLocation();
  const { pathname } = location;
  const isCandidate = !['ADMIN', 'SUPERADMIN'].some((val) => user?.roles?.includes(val));
  if (!checkisAuthenticated) {
    return <Redirect to={`/signin?pathto=${pathname}`} />;
  }

  if (isCandidate && !allowedRef?.includes(path)) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      {isCandidate && (
        <CandidateSidebar candidate={isCandidate}>
          <Suspense fallback={<div>Loading...</div>}>
            <AuthorizedRoute {...rest} path={path} render={renderComponent(Component)} />
          </Suspense>
        </CandidateSidebar>
      )}
      {!isCandidate && (
        <Sidebar candidate={isCandidate}>
          <Suspense fallback={<div></div>}>
            <AuthorizedRoute {...rest} path={path} render={renderComponent(Component)} />
          </Suspense>
        </Sidebar>
      )}
    </>
  );
};

export default AuthenticatedRoute;
